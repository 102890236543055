import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import { SEO } from '../components/SEO';
import { Layout } from '../layouts/Layout';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = Layout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <SEO title="FAQs" mdxType="SEO" />
    <h1>{`FAQs`}</h1>
    <h2>{`How to publish a new version of library?`}</h2>
    <p>{`You don't need to publish the library anymore. The library is now in the `}<inlineCode parentName="p">{`ulule/ulule`}</inlineCode>{` monorepo, so no need to publish to use it.`}</p>
    <h2>{`How to use a local version of library in a project for debugging?`}</h2>
    <p>{`There is no need to `}<inlineCode parentName="p">{`link`}</inlineCode>{` anymore, thanks to `}<inlineCode parentName="p">{`yarn`}</inlineCode>{` workspace, `}<inlineCode parentName="p">{`ulule/ulule`}</inlineCode>{` can directly reference `}<inlineCode parentName="p">{`owl-kit`}</inlineCode>{` as a dependency without publication or build step.`}</p>
    <h2>{`Which browsers are supported?`}</h2>
    <p>{`The design system supports the same set of browsers as the current React version.`}</p>
    <p>{`Some styles of components could be broken on IE11 and you could have to use polyfill to avoid errors.`}</p>
    <h2>{`Does it work with server-side rendering?`}</h2>
    <p>{`Yes, but you will have to setup the Node server to inject all styles from styled-components. You can see example in `}<a parentName="p" {...{
        "href": "https://www.styled-components.com/docs/advanced#server-side-rendering"
      }}>{`styled-components documentation`}</a></p>


    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      